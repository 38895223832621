import { Box, Button, Divider, Grid, Link, Menu, MenuItem, Stack, Typography } from '@mui/material'
import { useState, type ReactNode } from 'react'
import MenuIcon from '@mui/icons-material/Menu'
import type { AuthenticatedUser } from '../../lib/auth'
import type { RequestUrl } from '../../lib/url'

type Props = {
  children: ReactNode
  user: AuthenticatedUser | null
  requestUrl: RequestUrl
  headerContent?: ReactNode
  unsubscribe?: {
    subscription: PushSubscription
    onUnsubscribe: () => void
  }
  sha?: string | null
}

const pages = [
  { urlPath: '/', text: 'Callbox' },
  { urlPath: '/ringer', text: 'Ringer' },
  { urlPath: '/cha-leaderboard', text: 'Cha Leaderbaord' },
]

export function FrameworkPage(props: Props) {
  const requestUrl = new URL(props.requestUrl)
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  return (
    <Box sx={{ flexGrow: 1 }}>
      <Stack spacing={1} direction={'row'}>
        {props.user ? (
          <>
            <Button onClick={e => setAnchorEl(e.currentTarget)}>
              <MenuIcon />
            </Button>
            <Menu anchorEl={anchorEl} open={!!anchorEl} onClose={() => setAnchorEl(null)}>
              {pages.map(page => {
                return (
                  <MenuItem key={page.urlPath}>
                    <Button disabled={page.urlPath === requestUrl.pathname} LinkComponent={Link} href={page.urlPath}>
                      {page.text}
                    </Button>
                  </MenuItem>
                )
              })}

              <Divider />
              <MenuItem>
                <Button LinkComponent={Link} href={'/api/google/logout'}>
                  Logout
                </Button>
              </MenuItem>
              {props.unsubscribe ? (
                <>
                  <Divider />
                  <MenuItem>
                    <Button
                      color="warning"
                      onClick={() => {
                        void props.unsubscribe?.subscription?.unsubscribe()
                        props.unsubscribe?.onUnsubscribe()
                      }}
                    >
                      Unsubscribe
                    </Button>
                  </MenuItem>
                </>
              ) : null}
            </Menu>
          </>
        ) : null}
        {props.headerContent}
        {props.user && props.sha ? (
          <Box style={{ marginLeft: 'auto' }}>
            <Typography variant="overline" color={'gray'}>
              {props.sha.slice(0, 8)}
            </Typography>
          </Box>
        ) : null}
      </Stack>
      <Grid container justifyContent={'center'} alignItems={'center'} direction={'column'}>
        <Stack direction={'column'} spacing={1}>
          {props.children}
        </Stack>
      </Grid>
    </Box>
  )
}
